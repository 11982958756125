import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.css'
import FullScreenLoader from '../components/FullScreenLoader'
import withRetailUsers from '../components/Elements/withRetailUsers'
import Add from '@material-ui/icons/Add'
import IconButtonWrapper from '../components/Elements/Buttons/IconButton'
import withGroups from '../components/Elements/withGroups'
import GroupModal from '../components/Elements/Group/GroupModal'
import GroupSearch from '../components/Elements/Group/GroupSearch'
import GroupTree from '../components/Elements/Group/GroupTree'
import { ExportGroupsToCSV } from '../components/ExportGroupsToCSV'

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  margin: 10px;
`

const GroupsPage = props => {
  return (
    <React.Fragment>
      <Layout>
        <HeaderStyled>
          <h1>Groups</h1>
          <IconButtonWrapper
            color={'green'}
            onClick={() => {
              props.setEditGroup(false)
              props.setShowGroupModal(true)
            }}
            icon={<Add style={{ fontSize: 25 }} />}
          />
        </HeaderStyled>
        <br />
        <br />

        <FullScreenLoader loading={props.loading} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
          }}
        >
          <GroupSearch {...props} />
          <ExportGroupsToCSV
            dataObject={props.groups}
            rid={props.selected_rid}
            tags={props.retailTags}
          />
        </div>

        <GroupTree {...props} />
      </Layout>
      <GroupModal {...props} />
    </React.Fragment>
  )
}

export default withRetailUsers(withGroups(GroupsPage))
