import React, { useState } from 'react'
import Tree from '@naisutech/react-tree'

const GroupTree = props => {
  const [clickedGroup, setClickedGroup] = useState({ id: '' })

  const groupsToTreeData = groups => {
    let nodes = []
    Object.keys(groups).forEach(group_oid => {
      const group = groups[group_oid]
      const node = { id: group.oid, parentId: group.parent, label: group.name }

      nodes.push(node)
    })
    return nodes
  }

  const onSelect = group => {
    if (group.id === clickedGroup.id) {
      props.setSelectedGroup({
        ...props.groups[group.id],
      })
      props.setEditGroup(true)
      props.setShowGroupModal(true)
    } else {
      setClickedGroup(group)
    }
  }

  return (
    <div style={{ width: '50%', display: 'flex', flexGrow: 1 }}>
      <Tree
        nodes={groupsToTreeData(props.groups)}
        onSelect={onSelect}
        grow
        theme={'light'}
      />
    </div>
  )
}
export default GroupTree
